import PropTypes from "prop-types";
import React from "react";

import BusinessLocator from "../components/business-locator/BusinessLocator";
import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function BusinessLocatorPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "BusinessLocator", "StoreLocator"]}
          location={location}
          metaDescription="The first all-in one solution for store locators. Add one to your app or website within minutes. Mobile-friendly, customisable and affordable."
          title="BusinessLocator"
        />
        <Page darkblue>
          <BusinessLocator />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

BusinessLocatorPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default BusinessLocatorPage;
